import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Menu, MenuProps } from "antd";
import { UserRole } from "../../models/UserRole";
import { authSelector } from "../../redux/slides/auth.slide";
import "./menuSide.module.scss";
import { Link, useLocation } from "react-router-dom";
import {
  AppstoreOutlined,
  UserOutlined,
  AppstoreAddOutlined,
  SettingOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import React from "react";
import { routers } from "../../constants/router.constant";
import { useTranslation } from "react-i18next";
import CountUnreadProjects from "../Project/CountUnreadProjects";
import { ProjectStatus } from "../../models/Project";
import { unreadActions } from "../../redux/slides/unread.slide";
import projectServices from "../../services/project.service";
import { Archive } from "react-bootstrap-icons";

const MenuSide = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const auth = useSelector(authSelector);
  const [selected, setSelected] = useState(
    auth.user.role === UserRole.BUYER ? "drafts" : "placedOrders"
  );

  const getCountUnread = async (statuses: ProjectStatus[], key: any) => {
    try {
      const rs = await projectServices.countUnread({ statuses });
      dispatch(unreadActions.setData({ key, data: rs }));
      return rs;
    } catch (error) {}
    return 0;
  };

  const initCountUnread = async () => {
    if (auth.user?.role === UserRole.SELLER) {
      await getCountUnread(
        CountUnreadProjects.STATUS_KEYS.SELLER_GROUP1,
        "GROUP1"
      );
      await getCountUnread(
        CountUnreadProjects.STATUS_KEYS.SELLER_GROUP2,
        "GROUP2"
      );
    } else {
      await getCountUnread(
        CountUnreadProjects.STATUS_KEYS.BUYER_GROUP2_SUB1,
        "BUYER_GROUP2_SUB1"
      );
      await getCountUnread(
        CountUnreadProjects.STATUS_KEYS.BUYER_GROUP2_SUB2,
        "BUYER_GROUP2_SUB2"
      );
    }
    await getCountUnread(CountUnreadProjects.STATUS_KEYS.GROUP3, "GROUP3");
    await getCountUnread(CountUnreadProjects.STATUS_KEYS.GROUP4, "GROUP4");
  };

  useEffect(() => {
    const path = location.pathname;
    const menu = menuSides[auth?.user?.role];
    if (path.indexOf("settings") > -1) {
      const items = menu.find((m: any) => m.key === "settings");
      if (items?.children) {
        const key = items.children?.find(
          (i: any) => path.indexOf(i.key) > -1
        )?.key;
        setSelected(key);
      } else {
        setSelected("settings");
      }
    } else {
      if (path === "/" || path === "/seller" || path === "/seller/") {
        setSelected("requested-orders");
      }
      if (path === "/buyer") {
        setSelected("drafts");
      }
      if (path.indexOf("customers") > -1) {
        setSelected("customers");
      }
      if (path.indexOf("self-projects") > -1) {
        setSelected("self-projects");
      }
      if (path.indexOf(routers.PROJECTS_BUYER_GROUP2_SUB1) > -1) {
        setSelected("received-offers");
      }
      if (path.indexOf(routers.PROJECTS_BUYER_GROUP2_SUB2) > -1) {
        setSelected("requested-offers");
      }
      if (path.indexOf(routers.PROJECTS_SELLER_GROUP2) > -1) {
        setSelected("sent-offers");
      }
      if (path.indexOf(routers.PROJECTS_GROUP3) > -1) {
        setSelected("completed");
      }
      if (path.indexOf(routers.PROJECTS_GROUP4) > -1) {
        setSelected("cancelled");
      }
      if (path.indexOf("self-projects/drafts") > -1) {
        setSelected("self-draft");
      }
      if (path.indexOf("dispatched-offers") > -1) {
        setSelected("self-dispatched-offers");
      }
      if (path.indexOf(routers.PROJECTS_ARCHIVE) > -1) {
        setSelected("projects-archive");
      }
      if (path.indexOf(routers.SELF_PROJECTS_ARCHIVE) > -1) {
        setSelected("self-projects-archive");
      }
    }
    initCountUnread();
  }, []);

  const onClick: MenuProps["onClick"] = (e: any) => {
    setSelected(e.key);
  };

  const menuSides: any = {
    [UserRole.BUYER]: [
      {
        key: `home`,
        icon: React.createElement(AppstoreOutlined),
        label: t("menu.projects"),
        children: [
          {
            key: `drafts`,
            label: (
              <div className="d-flex align-items-center">
                <Link to={`/buyer${routers.HOME}`}>
                  {t("menu.project.group1")}
                </Link>
              </div>
            ),
          },
          {
            key: `received-offers`,
            label: (
              <div className="d-flex align-items-center">
                <Link to={`/buyer${routers.PROJECTS_BUYER_GROUP2_SUB1}`}>
                  {t("menu.project.buyer.group2.sub1")}
                </Link>
                <CountUnreadProjects mode="BUYER_GROUP2_SUB1" />
              </div>
            ),
          },
          {
            key: `requested-offers`,
            label: (
              <div className="d-flex align-items-center">
                <Link to={`/buyer${routers.PROJECTS_BUYER_GROUP2_SUB2}`}>
                  {t("menu.project.buyer.group2.sub2")}
                </Link>
                <CountUnreadProjects mode="BUYER_GROUP2_SUB2" />
              </div>
            ),
          },
          {
            key: `completed`,
            label: (
              <div className="d-flex align-items-center">
                <Link to={`/buyer${routers.PROJECTS_GROUP3}`}>
                  {t("menu.project.group3")}
                </Link>
                <CountUnreadProjects mode="GROUP3" />
              </div>
            ),
          },
          {
            key: `cancelled`,
            label: (
              <div className="d-flex align-items-center">
                <Link to={`/buyer${routers.PROJECTS_GROUP4}`}>
                  {t("menu.project.group4")}
                </Link>
                <CountUnreadProjects mode="GROUP4" />
              </div>
            ),
          },
        ],
      },
      {
        key: `settings`,
        icon: React.createElement(SettingOutlined),
        label: (
          <Link to={`/buyer${routers.ACCOUNT_SETTINGS}`}>
            {t("menu.settings")}
          </Link>
        ),
      },
      {
        key: `projects-archive`,
        icon: React.createElement(Archive),
        label: (
          <div className="d-flex align-items-center">
            <Link to={`/buyer${routers.PROJECTS_ARCHIVE}`}>
              {t("menu.project.archive")}
            </Link>
          </div>
        ),
      },
    ],
    [UserRole.SELLER]: [
      {
        key: `home`,
        icon: React.createElement(AppstoreOutlined),
        label: <Link to={`/seller${routers.HOME}`}>{t("menu.orders")}</Link>,
        children: [
          {
            key: `requested-orders`,
            label: (
              <div className="d-flex align-items-center">
                <Link to={`/seller${routers.HOME}`}>
                  {t("menu.project.seller.group1")}
                </Link>
                <CountUnreadProjects mode="GROUP1" />
              </div>
            ),
          },
          {
            key: `sent-offers`,
            label: (
              <div className="d-flex align-items-center">
                <Link to={`/seller${routers.PROJECTS_SELLER_GROUP2}`}>
                  {t("menu.project.seller.group2")}
                </Link>
                <CountUnreadProjects mode="GROUP2" />
              </div>
            ),
          },
          {
            key: `completed`,
            label: (
              <div className="d-flex align-items-center">
                <Link to={`/seller${routers.PROJECTS_GROUP3}`}>
                  {t("menu.project.group3")}
                </Link>
                <CountUnreadProjects mode="GROUP3" />
              </div>
            ),
          },
          {
            key: `cancelled`,
            label: (
              <div className="d-flex align-items-center">
                <Link to={`/seller${routers.PROJECTS_GROUP4}`}>
                  {t("menu.project.group4")}
                </Link>
                <CountUnreadProjects mode="GROUP4" />
              </div>
            ),
          },
        ],
      },
      {
        key: `self-projects`,
        icon: React.createElement(AppstoreAddOutlined),
        label: (
          <Link to={`/seller${routers.SELF_PROJECTS}`}>
            {t("menu.project.seller.self")}
          </Link>
        ),
      },
      {
        key: `customers`,
        icon: React.createElement(UserOutlined),
        label: (
          <Link to={`/seller${routers.CUSTOMERS}`}>{t("menu.customers")}</Link>
        ),
      },
      {
        key: `settings`,
        icon: React.createElement(SettingOutlined),
        label: t("menu.settings"),
        children: [
          {
            key: `material`,
            label: (
              <Link to={`/seller${routers.MATERIAL_SETTINGS}`}>
                {t("menu.materials")}
              </Link>
            ),
          },
          {
            key: `machine`,
            label: (
              <Link to={`/seller${routers.MACHINE_SETTINGS}`}>
                {t("menu.machines")}
              </Link>
            ),
          },
          {
            key: `surface`,
            label: (
              <Link to={`/seller${routers.SURFACE_TREATMENT_SETTINGS}`}>
                {t("menu.surfaceTreatments")}
              </Link>
            ),
          },
          {
            key: `quick-settings`,
            label: (
              <Link to={`/seller${routers.QUICK_SETTINGS}`}>
                {t("menu.quickSetting")}
              </Link>
            ),
          },
          {
            key: `account-settings`,
            label: (
              <Link to={`/seller${routers.ACCOUNT_SETTINGS}`}>
                {t("menu.accountSettings")}
              </Link>
            ),
          },
        ],
      },
      {
        key: `self-projects-archive`,
        icon: React.createElement(Archive),
        label: (
          <div className="d-flex align-items-center">
            <Link to={`/seller${routers.SELF_PROJECTS_ARCHIVE}`}>
              {t("menu.project.archive")}
            </Link>
          </div>
        ),
      },
    ],
  };

  return (
    <Menu
      theme="dark"
      mode="inline"
      defaultSelectedKeys={["drafts"]}
      defaultOpenKeys={["home", "self-projects", "settings"]}
      selectedKeys={[selected]}
      style={{ borderRight: 0 }}
      items={menuSides[auth?.user?.role || UserRole.BUYER]}
      onClick={onClick}
    />
  );
};

export default MenuSide;
