import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { TableFilter, TableParams } from "../../models/Table";
import { ProjectStatus } from "../../models/Project";
import "swiper/css";
import "./project.module.scss";
import { authSelector } from "../../redux/slides/auth.slide";
import ListProjects from "../../components/Project/ListProjects";
import { UserRole } from "../../models/UserRole";
import { useTranslation } from "react-i18next";
import dashboardServices from "../../services/dashboard.service";
import CurrencyFormat from "../../components/Format/CurrencyFormat";
import { routers } from "../../constants/router.constant";

const onChange = (key: string) => {
  // console.log(key);
};

export function ProjectPage() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const auth = useSelector(authSelector);
  const [dashboard, setDashboard] = useState<any>({});
  const [projectListTab, setProjectListTab] = useState<string>();
  const [title, setTitle] = useState("projects");
  const [description, setDescription] = useState("project.draft.description");

  const getDashboard = async () => {
    try {
      const rs = await dashboardServices.getData();
      setDashboard(rs);
    } catch (error) {}
  };

  useEffect(() => {
    const path = location.pathname?.replace(`/${auth.user?.role}`, "");
    setProjectListTab(path);
    switch (path) {
      case "":
      case "/":
        setTitle(
          auth.user?.role === UserRole.BUYER
            ? "menu.project.group1"
            : "menu.project.seller.group1"
        );
        setDescription(
          auth.user?.role === UserRole.BUYER
            ? "project.draft.description"
            : "project.openInquiries.description"
        );
        break;
      case routers.PROJECTS_GROUP2:
        setTitle("menu.project.group2");
        setDescription("project.offers.description");
        break;
      case routers.PROJECTS_SELLER_GROUP2:
        setTitle("menu.project.seller.group2");
        setDescription("project.sentOffers.description");
        break;
      case routers.PROJECTS_BUYER_GROUP2_SUB1:
        setTitle("menu.project.buyer.group2.sub1");
        setDescription("project.requestedOffers.description");
        break;
      case routers.PROJECTS_BUYER_GROUP2_SUB2:
        setTitle("menu.project.buyer.group2.sub2");
        setDescription("project.receivedOffers.description");
        break;
      case routers.PROJECTS_GROUP3:
        setTitle("menu.project.group3");
        setDescription("project.completedOffers.description");
        break;
      case routers.PROJECTS_GROUP4:
        setTitle("menu.project.group4");
        setDescription("project.cancelledOffers.description");
        break;
      case routers.PROJECTS_ARCHIVE:
        setTitle("menu.project.archive");
        setDescription("");
        break;
      case routers.SELF_PROJECTS_ARCHIVE:
        setTitle("menu.project.archive");
        setDescription("");
        break;
      default:
        setTitle("projects");
        break;
    }

    // initCountUnread();
    if (auth.user?.role === UserRole.SELLER) {
      getDashboard();
    }
  }, [location]);

  // const getProjects = async () => {
  //   setLoading(true);
  //   try {
  //     const tableFilters: TableFilter = {
  //       sortDir: tableParams.order != "descend" ? "ASC" : "DESC",
  //       sortProperty: tableParams.field?.toString(),
  //       page: (tableParams.pagination?.current || 1) - 1,
  //       size: tableParams.pagination?.pageSize,
  //       ...filters,
  //     };
  //     const rs: TableData = await projectServices.list(tableFilters);
  //     setTableParams({
  //       ...tableParams,
  //       pagination: {
  //         ...tableParams.pagination,
  //         total: rs.totalItems,
  //       },
  //     });
  //   } catch (error) {}
  //   dispatch(loadingActions.show(false));
  //   setLoading(false);
  // };

  // useEffect(() => {
  //   getProjects();
  // }, [JSON.stringify(tableParams), JSON.stringify(filters)]);

  return (
    <div>
      {auth.user.role === UserRole.SELLER && (
        <>
          <h3>{t("dashboard")}</h3>
          {/* {description && <p>{t(description)}</p>} */}
          <div className="row statistic">
            <div className="col col-6 col-md-4 col-lg">
              <div className="b-statistic total-projects">
                <h5 className="mb-0">{dashboard.numOfProjects}</h5>
                <label>{t("statistic.totalProjects")}</label>
              </div>
            </div>
            <div className="col col-6 col-md-4 col-lg">
              <div className="b-statistic total-parts">
                <h5 className="mb-0">{dashboard.numOfParts}</h5>
                <label>{t("statistic.totalParts")}</label>
              </div>
            </div>
            <div className="col col-6 col-md-4 col-lg">
              <div className="b-statistic total-calculations">
                <h5 className="mb-0">{dashboard.numOfCalculations}</h5>
                <label>{t("statistic.totalCalculations")}</label>
              </div>
            </div>
            <div className="col col-6 col-md-6 col-lg">
              <div className="b-statistic self-calculations">
                <h5 className="mb-0">{dashboard.numOfSelfCalculations}</h5>
                <label>{t("statistic.selfCalculations")}</label>
              </div>
            </div>
            <div className="col col-12 col-md-6 col-lg">
              <div className="b-statistic total-sales">
                <h5 className="mb-0">
                  <CurrencyFormat value={dashboard?.totalSales} showZero />
                </h5>
                <label>{t("statistic.totalSales")}</label>
              </div>
            </div>
          </div>
        </>
      )}
      <h5 className="mt-1">{t(title)}</h5>
      {description && <p className="projects-title-description">
        <i>{t(description)}</i>
      </p>}
      {auth.user.role === UserRole.BUYER && (
        <>
          {(projectListTab === "" || projectListTab === "/") && (
            <ListProjects statuses={[ProjectStatus.IN_PROGRESS]} />
          )}
          {projectListTab === routers.PROJECTS_BUYER_GROUP2_SUB1 && (
            <ListProjects
              statuses={[
                ProjectStatus.CHECKOUTED,
                ProjectStatus.REQUESTED_OFFER,
              ]}
            />
          )}
          {projectListTab === routers.PROJECTS_BUYER_GROUP2_SUB2 && (
            <ListProjects statuses={[ProjectStatus.PRICE_ADAPTED]} />
          )}
          {projectListTab === routers.PROJECTS_ARCHIVE && (
            <ListProjects
              statuses={[
                ProjectStatus.IN_PROGRESS,
              ]}
              isArchived={true}
            />
          )}
        </>
      )}
      {auth.user.role === UserRole.SELLER && (
        <>
          {(projectListTab === "" || projectListTab === "/") && (
            <ListProjects
              statuses={[
                ProjectStatus.CHECKOUTED,
                ProjectStatus.REQUESTED_OFFER,
              ]}
            />
          )}
          {projectListTab === routers.PROJECTS_SELLER_GROUP2 && (
            <ListProjects statuses={[ProjectStatus.PRICE_ADAPTED]} />
          )}
        </>
      )}
      {projectListTab === routers.PROJECTS_GROUP3 && (
        <ListProjects
          statuses={[
            ProjectStatus.SELLER_APPROVED,
            ProjectStatus.BUYER_APPROVED,
          ]}
        />
      )}
      {projectListTab === routers.PROJECTS_GROUP4 && (
        <ListProjects
          statuses={[
            ProjectStatus.BUYER_REJECTED,
            ProjectStatus.SELLER_REJECTED,
          ]}
        />
      )}
      {projectListTab === routers.SELF_PROJECTS_ARCHIVE && (
            <ListProjects
              statuses={[
                ProjectStatus.IN_PROGRESS,
              ]}
              isArchived={true}
            />
      )}
    </div>
  );
}
