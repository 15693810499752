import { Modal, Space, Button, Input, notification } from "antd";
import LocalizationName from "../Locale/LocalizationName";
import { SearchOutlined } from "@ant-design/icons";
import { HTMLAttributes, useEffect, useState } from "react";
import { MaterialGroup } from "app/models/MaterialGroup";
import { CuttingType } from "app/models/CuttingType";
import { PartViewMode } from "app/models/PartViewMode";
import { useSelector } from "react-redux";
import { materialSelector } from "app/redux/slides/material.slide";
import { Material } from "app/models/Material";
import { TechDrawingData } from "app/models/TechDrawingData";
import RobotCalc from "./RobotCalc";
import { useTranslation } from "react-i18next";
import materialServices from "app/services/material.service";
import { Part } from "app/models/Part";

interface DefaultProps extends HTMLAttributes<any> {
  mode?: PartViewMode;
  cuttingType: CuttingType;
  dataPart: any;
  requestingTechDrawingData?: any;
  techDrawingData?: TechDrawingData;
  onMaterialSelectedCallBack: any;
  onSetMaterialbyRobotCallBack?: any;
  part?: Part;
  materialProp?: any;
}

const renderPriceSymbol = (price: number) => {
  const p = Math.floor(price);
  switch (p) {
    case 0:
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
      return (
        <>
          <b>€</b>€€€
        </>
      );
    case 6:
    case 7:
    case 8:
      return (
        <>
          <b>€€</b>€€
        </>
      );
    case 9:
    case 10:
    case 11:
    case 12:
    case 13:
    case 14:
    case 15:
      return (
        <>
          <b>€€€</b>€
        </>
      );
  }
  return (
    <>
      <b>€€€€</b>
    </>
  );
};

function MaterialSelectModal(props: DefaultProps) {
  const [api, contextHolder] = notification.useNotification();
  const {
    cuttingType,
    onMaterialSelectedCallBack,
    requestingTechDrawingData,
    techDrawingData,
    part,
  } = props;

  const [groupTabSeleted, setGroupTabSeleted] = useState<MaterialGroup>();
  const [materials, setMaterials] = useState<Material[]>([]);
  const [openMaterial, setOpenMaterial] = useState(false);
  const [search, setSearch] = useState("");
  const [filterMaterials, setFilterMaterials] = useState<Material[]>();
  const [filterMaterialGroups, setFilterMaterialGroups] =
    useState<MaterialGroup[]>();
  const { groups, cuttingMachineGroups } = useSelector(materialSelector);
  const [material, setMaterial] = useState<Material | undefined>(
    part?.material
  );
  const [materialGroup, setMaterialGroup] = useState(
    !!part
      ? groups?.find((x: MaterialGroup) => x.id === part?.materialGroupId) ||
          undefined
      : undefined
  );
  const { t } = useTranslation();
  const materialGroups: MaterialGroup[] =
    (cuttingType === CuttingType.MILLING_AND_TURNING
      ? groups.filter((g: any) => g.materials && g.materials.length > 0)
      : cuttingMachineGroups.filter(
          (g: any) => g.materials && g.materials.length > 0
        )) || [];

  const showModal = () => {
    setOpenMaterial(true);
  };

  const hideModal = () => {
    setOpenMaterial(false);
  };

  const onMaterialGroupChange = (data?: MaterialGroup) => {
    if (data) {
      setGroupTabSeleted(data);
    } else {
      setGroupTabSeleted(undefined);
    }
  };

  const onSearchChange = (e: any) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    if (!!groupTabSeleted) {
      setMaterials(
        materialGroups.find((g) => g.id === groupTabSeleted.id)?.materials || []
      );
    }
  }, [groupTabSeleted]);

  useEffect(() => {
    if (material) {
      const g = materialGroups.find((g) =>
        g.materials?.find((m) => m.id === material?.id)
      );
      setMaterialGroup(g);
    }
  }, [material]);

  useEffect(() => {
    if (materialGroups && materials && search) {
      if (groupTabSeleted?.materials) {
        const rs: any = groupTabSeleted?.materials?.filter(
          (m) =>
            `${m.name}${m.number}`.toLowerCase().indexOf(search.toLowerCase()) >
            -1
        );
        setFilterMaterials(rs);
      }

      const groups = materialGroups.map((g) => {
        const group = { ...g };
        group.materials =
          g.materials?.filter(
            (m) =>
              `${m.name}${m.number}`
                .toLowerCase()
                .indexOf(search.toLowerCase()) > -1
          ) || [];
        return group;
      });
      setFilterMaterialGroups(groups);
    } else {
      setFilterMaterials(undefined);
      setFilterMaterialGroups(undefined);
    }
  }, [search, groupTabSeleted]);

  const onMaterialSelected = (data: Material) => {
    setMaterial(data);
    onMaterialSelectedCallBack(data);
    hideModal();
  };

  const getMaterial = async (id: number) => {
    try {
      const rs: Material = await materialServices.detail(id);
      const group = materialGroups?.find((m) => m.id === rs.groupId);
    //   setMaterial(rs);
      onMaterialSelected(rs)
      setMaterialGroup(group);
    } catch (error) {}
  };

  const onSetMaterialbyRobot = () => {
    if (techDrawingData?.materialId) getMaterial(techDrawingData?.materialId);
  };

  useEffect(() => {
    if (techDrawingData && techDrawingData.materialId) {
      getMaterial(techDrawingData.materialId);
    }
  }, [techDrawingData]);

  return (
    <>
      {contextHolder}
      <div className="d-flex align-items-center">
        <a className="text-primary" onClick={showModal}>
          {material ? (
            <>
              {material?.name} (
              <LocalizationName
                localizations={materialGroup?.localizations}
                valueDefault={materialGroup?.name}
              />
              )
            </>
          ) : (
            t("pleaseSelect")
          )}
        </a>
        &nbsp;
        {requestingTechDrawingData || !!techDrawingData?.materialId ? (
          <RobotCalc
            className="pointer"
            loading={requestingTechDrawingData}
            completed={
              !requestingTechDrawingData &&
              techDrawingData?.materialId === material?.id
            }
            onClick={onSetMaterialbyRobot}
            message={t("part.robot.material.message")}
          />
        ) : null}
      </div>
      <Modal
        className="selectMaterial"
        open={openMaterial}
        onCancel={hideModal}
        footer={null}
      >
        <div className="row">
          <div className="col col-3 col-md-4 group pt-5">
            <Space
              direction="vertical"
              className="group-menu"
              style={{ width: "100%" }}
            >
              <Button
                key={`group-0`}
                type={!groupTabSeleted ? "default" : "text"}
                shape="round"
                block
                onClick={onMaterialGroupChange.bind(null, undefined)}
              >
                All
              </Button>
              {(filterMaterialGroups || materialGroups)
                ?.filter((m) => m.materials && m.materials.length > 0)
                .map((item) => (
                  <Button
                    key={`group-${item.id}`}
                    type={
                      materialGroup?.id === item.id
                        ? "primary"
                        : groupTabSeleted?.id === item.id
                        ? "default"
                        : "text"
                    }
                    shape="round"
                    block
                    onClick={onMaterialGroupChange.bind(null, item)}
                  >
                    <LocalizationName
                      localizations={item?.localizations}
                      valueDefault={item.name}
                    />
                  </Button>
                ))}
            </Space>
          </div>
          <div className="col col-9 col-md-8 ps-3 pe-3 pt-5">
            <Input
              placeholder="Search material"
              prefix={<SearchOutlined />}
              allowClear
              onChange={onSearchChange}
            />
            <div className="material-list">
              {groupTabSeleted && (
                <div className="group-item">
                  <p className="sub-l">
                    <LocalizationName
                      localizations={groupTabSeleted?.localizations}
                      valueDefault={groupTabSeleted?.name}
                    />
                  </p>
                  {(filterMaterials || materials).map((item, index) => (
                    <div
                      className="row material-item"
                      key={`material-${item.id}`}
                      onClick={onMaterialSelected.bind(null, item)}
                    >
                      <div className="col col-6"> {item.name}</div>
                      <div className="col">{item.number}</div>
                      <div className="col col-2 text-center">
                        {renderPriceSymbol(item.pricePerKilo)}
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {!groupTabSeleted &&
                (filterMaterialGroups || materialGroups)?.map(
                  (g, gIndex) =>
                    !!g.materials &&
                    g.materials.length > 0 && (
                      <div className="group-item" key={`group-${gIndex}`}>
                        <p className="sub-l">
                          <LocalizationName
                            localizations={g?.localizations}
                            valueDefault={g?.name}
                          />
                        </p>
                        {g.materials.map((item) => (
                          <div
                            className="row material-item"
                            key={`material-${item.id}`}
                            onClick={onMaterialSelected.bind(null, item)}
                          >
                            <div className="col col-6">{item.name}</div>
                            <div className="col">{item.number}</div>
                            {/* <div className="col col-2">{item.density}</div> */}
                            <div className="col col-2 text-center">
                              {renderPriceSymbol(item.pricePerKilo)}
                            </div>
                          </div>
                        ))}
                      </div>
                    )
                )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default MaterialSelectModal;
