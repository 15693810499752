import { KeyOutlined, ManOutlined, WomanOutlined } from "@ant-design/icons";
import { Button, Form, Input, InputNumber, Modal, Radio, Select, notification } from "antd";
import Utils from "app/utils";
import {
  useRulePassword,
  useRuleStringRequired,
} from "app/utils/FormValidation";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import AddressForm from "../../components/Billing/AddressForm";
import { User } from "../../models/User";
import { loadingActions } from "../../redux/slides/loading.slide";
import { userActions } from "../../redux/slides/user.slide";
import userServices from "../../services/user.service";
const { Option } = Select;
export function BuyerSettingPage() {
  const [api, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const rulePassword = useRulePassword();
  const ruleStringRequired = useRuleStringRequired();
  const [profile, setProfile] = useState<User>();
  const [form] = Form.useForm();
  const [formPassword] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [keySelected, setKeySelected] = useState("default");

  const menus = [
    {
      key: "default",
      label: t("settings.account"),
    },
    {
      key: "bulk-pricing",
      label: t("part.bulkPricing"),
    },
  ];

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    formPassword.resetFields();
  };

  const getMe = async () => {
    try {
      const rs = await userServices.me();
      setProfile(rs);
      form.setFieldsValue(rs);
    } catch (error) {
      // onLogout();
    }
  };

  useEffect(() => {
    getMe();
  }, []);

  const onMenuSelected = (m: any) => {
    setKeySelected(m.key);
  };

  const onFinish = async (values: User) => {
    dispatch(loadingActions.show(true));
    try {
      const rs = await userServices.updateAccount({
        ...profile,
        ...values,
      });
      api.success({
        message: `Success!`,
        description: `Updated Account`,
        placement: "topRight",
      });
      dispatch(userActions.setProfile(rs));
    } catch (e: any) {
      api.error({
        message: e.error,
        description: e.message,
        placement: "topRight",
      });
    }
    dispatch(loadingActions.show(false));
  };

  const onChangePasswordFinish = async (values: any) => {
    dispatch(loadingActions.show(true));
    try {
      await userServices.changePassword(values);
      api.success({
        message: `Success!`,
        description: `Password is changed!`,
        placement: "topRight",
      });
      handleCancel();
    } catch (e: any) {
      api.error({
        message: "Failed!",
        description: t("error.default"),
        placement: "topRight",
      });
    }
    dispatch(loadingActions.show(false));
  };
  return (
    <div>
      {contextHolder}
      <h4>{t("accountSettings")}</h4>
      <div className="b-settings">
        <div className="row">
          <div className="col col-12 col-lg d-lg-flex flex-lg-column gap-3 b-menus">
            {menus.map((m) => (
              <Button
                key={`m-${m.key}`}
                type={m.key === keySelected ? "primary" : "default"}
                shape="round"
                onClick={onMenuSelected.bind(null, m)}
              >
                {m.label}
              </Button>
            ))}
          </div>
          <div className="col col-12 col-lg-8 col-xl-10">
            <Form
              className="app-form"
              form={form}
              layout="vertical"
              name="basic"
              onFinish={onFinish}
              autoComplete="off"
              initialValues={{
                country: "Deutschland",
              }}
            >
              <Form.Item name="id" hidden>
                <Input />
              </Form.Item>
              {keySelected === "default" && (
                <>
                  <div className="row">
                    <div className="col col-12 col-lg-6">
                      <p className="sub-l">{t("contactPerson")}</p>
                      <div className="row">
                        <div className="col col-12 col-md-6">
                          <Form.Item
                            label={t("firstName")}
                            name="firstName"
                            rules={ruleStringRequired}
                          >
                            <Input />
                          </Form.Item>
                        </div>
                        <div className="col col-12 col-md-6">
                          <Form.Item
                            label={t("lastName")}
                            name="lastName"
                            rules={ruleStringRequired}
                          >
                            <Input />
                          </Form.Item>
                        </div>
                        <div className="mb-3" onClick={showModal}>
                          <Button icon={<KeyOutlined />}>
                            {t("changePassword")}
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className="col col-12 col-lg-6">
                      <div className="row">
                        <p className="sub-l">{t("contactInfomation")}</p>
                        <div className="col col-12 col-md-6">
                          <Form.Item name="gender" label={t("gender")}>
                            <Radio.Group>
                              <Radio.Button value="MALE">
                                <ManOutlined /> {t("male")}
                              </Radio.Button>
                              <Radio.Button value="FEMALE">
                                <WomanOutlined /> {t("female")}
                              </Radio.Button>
                            </Radio.Group>
                          </Form.Item>
                        </div>
                        <div className="col col-12 col-md-6">
                          <Form.Item
                            label={t("companyName")}
                            name="companyName"
                            rules={ruleStringRequired}
                          >
                            <Input />
                          </Form.Item>
                        </div>
                        <div className="col col-12 col-md-6">
                          <Form.Item label={t("phone")} name="phone">
                            <Input />
                          </Form.Item>
                        </div>
                        <div className="col col-12 col-md-6">
                          <Form.Item label={t("fax")} name="fax">
                            <Input />
                          </Form.Item>
                        </div>
                      </div>
                    </div>

                    {/* <hr className="mt-2" /> */}
                    <div className="col col-12 col-lg-6 mt-3">
                      <AddressForm
                        form={form}
                        data={profile?.billingAddress}
                        title={t("billingAddress")}
                        name="billingAddress"
                      />
                    </div>
                    <div className="col col-12 col-lg-6 mt-3">
                      <AddressForm
                        form={form}
                        data={profile?.shippingAddress}
                        title={t("shippingAddress")}
                        name="shippingAddress"
                        required={false}
                      />
                    </div>
                  </div>
                </>
              )}
              {keySelected === "bulk-pricing" && (
                <>
                  <div className="row">
                    <div className="col col-12 col-lg-6">
                      <p className="sub-l">{t("part.bulkPricing")}</p>
                      <div className="row">
                        <div className="col col-12 col-md-6">
                          <Form.Item
                            label={t("part.bulkPricing")}
                            name="quantityRef1"
                            extra={`${t("quantity")} ref 1`}
                            messageVariables={{
                              label: `${t("quantity")} ref 1`,
                            }}
                            rules={[{required: true}]}
                          >
                            <InputNumber onKeyDown={Utils.triggerTypeNumber} type="number" min={1} max={10000} />
                          </Form.Item>
                          <Form.Item
                            name="quantityRef2"
                            extra={`${t("quantity")} ref 2`}
                            messageVariables={{
                              label: `${t("quantity")} ref 2`,
                            }}
                            rules={[{required: true}]}
                          >
                            <InputNumber onKeyDown={Utils.triggerTypeNumber} type="number" min={1} max={10000} />
                          </Form.Item>
                          <Form.Item
                            name="quantityRef3"
                            extra={`${t("quantity")} ref 3`}
                            messageVariables={{
                              label: `${t("quantity")} ref 3`,
                            }}
                            rules={[{required: true}]}
                          >
                            <InputNumber onKeyDown={Utils.triggerTypeNumber} type="number" min={1} max={10000} />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              <hr className="mt-3" />
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  {t("saveChanges")}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
      <Modal
        title={t("changePassword")}
        open={isModalOpen}
        onOk={formPassword.submit}
        onCancel={handleCancel}
      >
        <Form
          form={formPassword}
          layout="vertical"
          name="basic"
          onFinish={onChangePasswordFinish}
          autoComplete="off"
        >
          <Form.Item
            label={t("oldPassword")}
            name="password"
            rules={rulePassword}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label={t("newPassword")}
            name="newPassword"
            rules={[{ required: true }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label={t("confirmPassword")}
            name="confirmNewPassword"
            rules={[
              { required: true },
              {
                validator: async (_, confirmPassword) => {
                  if (
                    formPassword.getFieldValue("newPassword") != confirmPassword
                  ) {
                    return Promise.reject(
                      new Error(t("validate.confirmPassword") || "")
                    );
                  }
                  return true;
                },
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
