import { Popover } from "antd";
import { Price } from "app/models/Price";
import CurrencyFormat from "../Format/CurrencyFormat";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

interface DefaultProps {
  price?: Price;
  lableClass?: string;
}

function TotalPartPrice(props: DefaultProps) {
  const { price, lableClass } = props;
  const { t } = useTranslation();

  return (
    <>
      {!!price?.totalPartPriceSurcharge && (
        <div className="d-flex">
          <label className={lableClass ?? lableClass}>
            {t("price.totalPartPriceSurcharge")}{" "}
            <Popover
              placement="right"
              content={t("price.totalPartPriceSurcharge.desc")}
              trigger="hover"
            >
              <InfoCircleOutlined />
            </Popover>
            :
          </label>
          <label className="ms-auto">
            <CurrencyFormat value={price?.totalPartPriceSurcharge} />
          </label>
        </div>
      )}
      <div className="d-flex">
        <label className={lableClass ?? lableClass}>
          {t("price.totalPartPrice")}:
        </label>
        <label className="ms-auto">
          <CurrencyFormat value={price?.totalPartPrice} />
        </label>
      </div>
    </>
  );
}

export default TotalPartPrice;
